import React from 'react';
import * as R from 'ramda';
import qs from 'qs';
import Layout from '../components/Layout';
import PropertiesRoll from '../components/PropertiesRoll';
import PortfolioPageHeroImage from '../components/PortfolioPageHeroImage';
import PortfolioTiles from '../components/PortfolioTiles';
import PageHelmet from '../components/PageHelmet';
import { ImageOverlay } from '../utils';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const PortfolioPageTemplate = ({
  heroImageObject,
  title,
  properties,
  seoObject,
  smallTitle,
  imageTitle,
  queryFilters,
  helmet,
  location,
  portfolioBannerText,
  isPreview,
}) => (
  <div className='page-container container content'>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <div className='full-width-padding no-overflow'>
      <div className='small-title'>{smallTitle}</div>
      <h1 className='page-title'>{title}</h1>
      <PortfolioPageHeroImage
        location={location}
        imageInfo={heroImageObject.image}
        classes='page-header-image'>
        <div className='image-title'>
          <hr />
          {location.search === ''
            ? imageTitle
            : portfolioBannerText && portfolioBannerText !== ''
            ? portfolioBannerText
            : imageTitle}
        </div>
        <ImageOverlay overlay={heroImageObject.overlay} />
      </PortfolioPageHeroImage>
      {location.search === '' ? (
        <>
          <div className='spacer'></div>
          <PortfolioTiles locale='en' isPreview={isPreview} />
          <div className='spacer'></div>
        </>
      ) : (
        <>
          <PropertiesRoll
            location={location}
            properties={properties}
            propertyObject='property_data_en'
            queryFilters={queryFilters}
            featuredImage={heroImageObject.image}
          />
        </>
      )}
    </div>
  </div>
);

const PortfolioPage = ({ data, location }) => {
  let propertiesList = data.allMarkdownRemark.edges;
  const pageData = data.markdownRemark.frontmatter;
  const { title, siteUrl } = useSiteMetadata();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });

  const portfolioBannerText =
    typeof window === 'undefined'
      ? null
      : window.localStorage.getItem('portfolioBannerText');

  if (!R.isEmpty(queryFilters)) {
    if (queryFilters.type !== 'all') {
      propertiesList = R.filter(
        R.pathEq(['node', 'frontmatter', 'type'], queryFilters.type),
        propertiesList
      );
    }
  }

  propertiesList.sort((a, b) => {
    // find index of valueA in hotelIds.frontmatter.properties.property.hotelId - index of b.node.frontmatter.title in hotelids
    const valueA = R.path(['node', 'frontmatter', 'title'], a);
    const valueB = R.path(['node', 'frontmatter', 'title'], b);
    const indexA = R.findIndex(R.pathEq(['property', 'hotelName'], valueA))(
      data.hotelNames.frontmatter.properties
    );
    const indexB = R.findIndex(R.pathEq(['property', 'hotelName'], valueB))(
      data.hotelNames.frontmatter.properties
    );
    return indexA - indexB;
  });
  return (
    <Layout>
      <PortfolioPageTemplate
        location={location}
        heroImageObject={pageData.heroImageObject}
        title={pageData.propertiesTitle}
        smallTitle={pageData.propertiesSmallTitle}
        imageTitle={pageData.imageTitle}
        seoObject={pageData.seoObject}
        properties={propertiesList}
        queryFilters={queryFilters}
        portfolioBannerText={portfolioBannerText}
        helmet={
          <MetaComponent
            title={`${pageData.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${pageData.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${pageData.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default PortfolioPage;

export const pageQuery = graphql`
  query PortfolioPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "portfolio-page" } }) {
      frontmatter {
        propertiesTitle
        propertiesSmallTitle
        imageTitle
        heroImageObject {
          overlay
          image
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
    hotelNames: markdownRemark(
      frontmatter: { templateKey: { eq: "portfolio-order" } }
    ) {
      frontmatter {
        properties {
          property {
            hotelName
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "property-listing" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            type
            title
            property_data_en {
              short_description
            }
            featuredImage
          }
        }
      }
    }
  }
`;
